export default function useDates() {
  const languageStore = { language: "en" };
  const getTime = (date: string) => {
    const newDate = new Date(date);
    return newDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  };

  const getDate = (date: string) => {
    const newDate = new Date(date);
    return newDate.toLocaleString(languageStore.language, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };
  const getDateWithTime = (date: string) => {
    const newDate = new Date(date);
    return newDate.toLocaleString(languageStore.language, {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  const getDay = (date: string) => {
    const newDate = new Date(date);
    return newDate.toLocaleString(languageStore.language, { weekday: "long" });
  };
  return {
    getTime,
    getDate,
    getDay,
    getDateWithTime,
  };
}
