import { reactive } from "vue";

const form = reactive({
  name: "",
  email: "",
  password: "",
  phone_number: "",
  organization: "",
  position: "",
  current_password: "",
  new_password: "",
  confirm_password: "",
});

const loginForm = {
  email: "",
  password: "",
};

export const useMyAccount = () => ({ form, loginForm });
