import axios from "axios";
import {
  IEventInfo,
  IEventLanguage,
  IEventSettings,
  IEventsParams,
  IEventV2,
  IVisitorFormSettings,
} from "@/models/event";
import { Resolve, IData } from ".";
import config from "./config";

const url = process.env.VUE_APP_SERVER_PATH;

export const resendRegistrationEmail = (payload: {
  attending_id: number;
  slug: string;
}) =>
  new Promise((resolve: Resolve<IEventInfo>) => {
    axios
      .post(
        `${url}/event-live/${payload.slug}/resend_registration_email`,
        payload,
        config()
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const deleteEvent = (eventId: number) =>
  new Promise((resolve: Resolve<IEventV2>, reject) => {
    axios
      .delete(`${url}/v2/events/${eventId}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getEvents = (params: IEventsParams) =>
  new Promise((resolve: Resolve<IData<IEventV2>>, reject) => {
    if (!params.page_size) {
      params.page_size = 100;
    }
    if (!params.page) {
      params.page = 1;
    }
    let requestUrl = `${url}/v2/events?page_size=${params.page_size}&page=${params.page}&ordering=-id`;

    if (params.search) {
      requestUrl += `&search=${params.search}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getEventById = (event: number) =>
  new Promise((resolve: Resolve<IEventV2>, reject) => {
    axios
      .get(`${url}/v2/events/${event}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const getEventBySlug = (event: string) =>
  new Promise((resolve: Resolve<IData<IEventV2>>, reject) => {
    axios
      .get(`${url}/v2/events?slug=${event}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getEventLanguage = (event: number) =>
  new Promise((resolve: Resolve<IData<IEventLanguage>>, reject) => {
    axios
      .get(`${url}/v2/event-languages?event=${event}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getEventSettings = (event: number) =>
  new Promise((resolve: Resolve<IData<IEventSettings>>, reject) => {
    axios
      .get(`${url}/v2/event-settings?event=${event}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getVisitorFormSettings = (event: number) =>
  new Promise((resolve: Resolve<IVisitorFormSettings>, reject) => {
    axios
      .get(`${url}/v2/visitor-form-settings/${event}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateVisitorFormSettings = (
  payload: Partial<IVisitorFormSettings>
) =>
  new Promise((resolve: Resolve<IData<IVisitorFormSettings>>, reject) => {
    axios
      .patch(
        `${url}/v2/visitor-form-settings/${payload.event_id}`,
        payload,
        config()
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getEventsById = (events: number[]) =>
  new Promise((resolve: Resolve<IEventV2>, reject) => {
    axios
      .get(`${url}/v2/events?id=${events.join(",")}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const addEvent = (event: Partial<IEventV2>) =>
  new Promise((resolve: Resolve<IEventV2>, reject) => {
    axios
      .post(`${url}/v2/events`, event, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getTermsAndConditions = (eventId: number) =>
  new Promise(
    (
      resolve: Resolve<
        IData<{
          id: number;
          link: string;
          link_text: string;
        }>
      >,
      reject
    ) => {
      axios
        .get(
          `${url}/v2/terms-and-conditions?event=${eventId}&ordering=-id`,
          config()
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );

export const getEventManagers = (event_id: number) =>
  new Promise((resolve: Resolve<any>) => {
    axios
      .get(`${url}/v2/event-managers?event=${event_id}`, config(true))
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const addEventManager = (eventManager: {
  user_account: number;
  event: number;
  name: string;
}) =>
  new Promise((resolve: Resolve<any>, reject) => {
    axios
      .post(`${url}/v2/event-managers`, eventManager, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const deleteEventManager = (id: number, event_id: number) =>
  new Promise((resolve: Resolve<any>, reject) => {
    axios
      .delete(`${url}/v2/event-managers/${id}?event=${event_id}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const editEvent = (event: Partial<IEventV2>) =>
  new Promise((resolve: Resolve<IEventV2[]>, reject) => {
    axios
      .patch(`${url}/v2/events/${event.id}`, event, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const uploadEventImage = (formData: FormData) =>
  new Promise((resolve: Resolve<{ id: number }>) => {
    axios
      .post(`${url}/v2/event-images`, formData, config(true))
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export interface IFile {
  file: string;
  id: number;
  name?: string;
  original_file_name?: string;
  url?: string;
  errors?: any;
}
