import { useLanguageStore } from "@/stores/language";
import { useUserStore } from "@/stores/user";
import { AxiosRequestConfig } from "axios";

export default (
  formData = false,
  customToken = "",
  ct = false
): AxiosRequestConfig => {
  const userStore = useUserStore();
  const languageStore = useLanguageStore();
  const token = customToken !== "" ? customToken : userStore.token;
  const contentType = formData
    ? "multipart/form-data;"
    : "application/vnd.beamian+json; version=1";
  if (ct) {
    return {
      headers: {
        // tslint:disable-next-line: object-literal-key-quotes
        "content-type": contentType,
        "Accept-Language": languageStore.language,
      },
    };
  }
  return {
    headers: token
      ? {
          // tslint:disable-next-line: object-literal-key-quotes
          Authorization: token !== "" ? `Token ${token}` : "",
          "content-type": contentType,
          "Accept-Language": languageStore.language,
        }
      : {
          // tslint:disable-next-line: object-literal-key-quotes
          "content-type": contentType,
          "Accept-Language": languageStore.language,
        },
  };
};
