import { reactive, toRefs } from "vue";

const state = reactive({
  isMobile: window.innerWidth <= 768,
});

window.addEventListener("resize", () => {
  state.isMobile = window.innerWidth <= 768;
});

export function useWindowWidth() {
  return toRefs(state);
}
