import { IVisitor } from "@/models/visitor";
import { defineStore } from "pinia";
import { ref } from "vue";

export interface AppSettings {
  theme: "light" | "dark";
  checkin_mode: "fast" | "slow";
}

export const useCheckinStore = defineStore("checkin", () => {
  const visitors = ref([] as IVisitor[]);
  const visitors_prop = process.env.VUE_APP_VISITORS;
  const visitorsString = localStorage.getItem(visitors_prop);

  const add = (visitor: IVisitor) => {
    visitors.value = [visitor].concat(visitors.value);
    localStorage.setItem(visitors_prop, JSON.stringify(visitors));
  };

  const setVisitors = (new_visitors: IVisitor[]) => {
    visitors.value = new_visitors;
  };

  if (visitorsString) {
    const local_visitors = JSON.parse(visitorsString);
    if (local_visitors.length > 0) {
      setVisitors(local_visitors);
    }
  }

  return {
    visitors,
    add,
  };
});
