import axios from "axios";
import { IAuth, IUserAuth, IV2 } from "@/models/auth";

import { Resolve } from ".";
import config from "./config";
import { IUser } from "@/models/user";

const url = process.env.VUE_APP_SERVER_PATH;

interface ILoginSuccess {
  token: string;
  user: number;
}

export const requestResetPassword = (user: { email: string }) =>
  axios.post(`${url}/v2/request-reset-password`, user, config());

export const confirmEmail = (payload: { email: string; confirmkey: string }) =>
  axios.post(`${url}/v2/checkin-signup/validate-user`, payload, config());

export const resetPasswordComplete = (user: {
  password: string;
  token: string;
  uidb64: string;
}) => axios.post(`${url}/v2/password-reset-complete`, user, config());

export const loginUser = (user: IUserAuth) =>
  new Promise(
    (
      resolve: Resolve<ILoginSuccess>,
      reject: Resolve<{ data: { detail: string } }>
    ) => {
      axios
        .post(`${url}/auth`, user, config())
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );

export const sendLoginLink = (user: { event: number; email: string }) =>
  axios.post(`${url}/v1/accounts/login-link`, user, config());

export const isAdmin = () =>
  new Promise((resolve: Resolve<IAuth>, reject) => {
    axios
      .get(`${url}/v2/services/is-admin`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getRoles = () =>
  new Promise((resolve: Resolve<IV2>, reject) => {
    axios
      .get(`${url}/v2`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getMe = () =>
  new Promise((resolve: Resolve<IUser>, reject) => {
    axios
      .get(`${url}/me`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
