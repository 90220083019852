import axios from "axios";
import { IEventV2, IExportFile } from "@/models/event";
import {
  IVisitor,
  IVisitorResponse,
  IVisitorsParams,
  IVisitorContactsParams,
  IVisitorContact,
  IVisitorTypeChoice,
} from "@/models/visitor";
import { Resolve, IData, IParams } from ".";
import config from "./config";
import { IAttendingFieldsEntity } from "@/models/fields";
import { IVisitorImportParams } from "@/models/visitor";

const url = process.env.VUE_APP_SERVER_PATH;

export interface IAttendeeLiveForm {
  sessions?: { field_type: string; value?: string }[];
  email: string;
  name: string;
  ticket_code?: string;
  addon_ids?: number[];
  bulk_code?: string;
  fields: IAttendingFieldsEntity[];
  pending?: boolean;
  awaiting_payment?: boolean;
  tag_id?: string;
  visitor_type?: number;
}

export interface IVisitorContactsExportsParams extends IParams {
  exhibitor?: number;
  event?: number;
}

export const addVisitorContactsExport = (
  exhibitor: number | undefined = undefined
) =>
  new Promise((resolve: Resolve<IExportFile>, reject) => {
    const requestUrl = `${url}/v2/visitor-contacts-exports`;

    const data = {
      exhibitor,
      source_type: "visitor",
    };

    axios
      .post(requestUrl, data, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const deleteVisitorContactsExport = (exportId: number) =>
  new Promise((resolve: Resolve<IExportFile>, reject) => {
    const requestUrl = `${url}/v2/visitor-contacts-exports/${exportId}`;

    axios
      .delete(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getVisitorContactsExports = (
  params: IVisitorContactsExportsParams
) =>
  new Promise((resolve: Resolve<IData<IExportFile>>, reject) => {
    if (!params.page_size) {
      params.page_size = 100;
    }

    let requestUrl = `${url}/v2/visitor-contacts-exports?page_size=${params.page_size}`;

    if (params.exhibitor) {
      requestUrl += `&exhibitor=${params.exhibitor}`;
    }
    if (params.event) {
      requestUrl += `&event=${params.event}`;
    }
    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const newAttendee = (event: IEventV2, attendeeForm: IAttendeeLiveForm) =>
  new Promise((resolve: Resolve<IVisitorResponse>, reject) => {
    axios
      .post(`${url}/event-live/${event.slug}/live_form`, attendeeForm, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getVisitorContacts = (params: IVisitorContactsParams) =>
  new Promise((resolve: Resolve<IData<IVisitorContact>>, reject) => {
    if (!params.page_size) {
      params.page_size = 100;
    }

    if (!params.page) {
      params.page = 1;
    }

    if (!params.ordering) {
      params.ordering = "-id";
    }

    let requestUrl = `${url}/v2/visitor-contacts?page_size=${params.page_size}&page=${params.page}`;

    if (params.ordering) {
      requestUrl += `&ordering=${params.ordering}`;
    }

    if (params.event) {
      requestUrl += `&event=${params.event}`;
    }

    if (params.email) {
      requestUrl += `&email=${encodeURIComponent(params.email)}`;
    }

    if (params.phone_nr) {
      requestUrl += `&phone_nr=${params.phone_nr}`;
    }

    if (params.exhibitor) {
      requestUrl += `&exhibitor=${params.exhibitor}`;
    }
    if (params.search) {
      requestUrl += `&search=${params.search}`;
    }
    if (params.favourite_status !== undefined) {
      requestUrl += `&favourite_status=${params.favourite_status}`;
    }
    if (params.sessions !== undefined) {
      requestUrl += `&sessions=${params.sessions}`;
    }
    if (params.interactions !== undefined) {
      requestUrl += `&interactions=${params.interactions}`;
    }
    if (params.product_interactions !== undefined) {
      requestUrl += `&product_interactions=${params.product_interactions}`;
    }
    if (params.exhibitor_invitations !== undefined) {
      requestUrl += `&exhibitor_invitations=${params.exhibitor_invitations}`;
    }
    if (params.bulk_codes !== undefined) {
      requestUrl += `&exhibitor_invitations=${params.bulk_codes}`;
    }
    if (params.overstaying !== undefined) {
      requestUrl += `&overstaying=${params.overstaying}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getVisitorTypes = (params: IVisitorsParams) =>
  new Promise((resolve: Resolve<IData<IVisitor>>, reject) => {
    if (!params.page_size) {
      params.page_size = 100;
    }

    if (!params.page) {
      params.page = 1;
    }

    let requestUrl = `${url}/v2/visitor-type-choices?page_size=${params.page_size}&page=${params.page}`;

    if (params.event) {
      requestUrl += `&visitor_type__event=${params.event}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getVisitors = (params: IVisitorsParams) =>
  new Promise((resolve: Resolve<IData<IVisitor>>, reject) => {
    if (!params.page_size) {
      params.page_size = 100;
    }

    if (!params.page) {
      params.page = 1;
    }

    let requestUrl = `${url}/v2/visitors?page_size=${params.page_size}&page=${params.page}&ordering=-id`;

    if (params.visitor_status) {
      requestUrl += `&status=${params.visitor_status}`;
    }

    if (params.event) {
      requestUrl += `&event=${params.event}`;
    }

    if (params.email) {
      requestUrl += `&email=${encodeURIComponent(params.email)}`;
    }

    if (params.tag) {
      requestUrl += `&tag=${params.tag}`;
    }
    if (params.register_code) {
      requestUrl += `&register_code=${params.register_code}`;
    }
    if (params.phone_nr) {
      requestUrl += `&phone_nr=${params.phone_nr}`;
    }
    if (params.search) {
      requestUrl += `&search=${params.search}`;
    }
    if (params.checkin_status !== "") {
      requestUrl += `&checkin_status=${params.checkin_status}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getVisitorTypeChoices = (params: IVisitorsParams) =>
  new Promise((resolve: Resolve<IData<IVisitorTypeChoice>>, reject) => {
    if (!params.page_size) {
      params.page_size = 100;
    }

    if (!params.page) {
      params.page = 1;
    }

    let requestUrl = `${url}/v2/visitor-type-choices?page_size=${params.page_size}&page=${params.page}`;

    if (params.event) {
      requestUrl += `&visitor_type__event=${params.event}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getVisitorById = (visitorId: number) =>
  new Promise((resolve: Resolve<IVisitor>) => {
    axios
      .get(`${url}/v2/visitors/${visitorId}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });
export const createVisitor = (payload: Partial<IVisitor>) =>
  new Promise((resolve: Resolve<IVisitor>, reject) => {
    axios
      .post(`${url}/v2/visitors`, payload, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateVisitor = (payload: Partial<IVisitor>) =>
  new Promise((resolve: Resolve<IVisitor>, reject) => {
    axios
      .patch(`${url}/v2/visitors/${payload.id}`, payload, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const deleteVisitor = (payload: Partial<IVisitor>) =>
  new Promise((resolve: Resolve<IVisitor>, reject) => {
    axios
      .delete(`${url}/v2/visitors/${payload.id}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getVisitorContactById = (params: {
  visitor: number;
  exhibitor?: number;
  event?: number;
}) =>
  new Promise((resolve: Resolve<IVisitorContact>, reject) => {
    let requestUrl = `${url}/v2/visitor-contacts/${params.visitor}`;
    if (params.exhibitor) {
      requestUrl += `?exhibitor=${params.exhibitor}`;
    }
    if (params.event) {
      requestUrl += `?event=${params.event}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export interface ImportVisitorsResponse {
  new: number;
  attending: number;
  error: number;
  warning: number;
  preview: string | boolean;
  error_lines: object[];
  tag?: number;
  warning_lines?: object[];
  id?: number;
  error_detail?: object;
}

export const importVisitors = (
  visitors: IVisitorImportParams[],
  event_id: number,
  preview: boolean
) =>
  new Promise((resolve: Resolve<ImportVisitorsResponse>, reject) => {
    const payload = { json_data: visitors, preview: preview };
    axios
      .post(`${url}/events/${event_id}/import_visitors`, payload, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });

export const sendEmailWithExampleFile = (event_id: number) => {
  return axios.get(
    `${url}/v2/events/${event_id}/import_visitors_template`,
    config()
  );
};
