/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
import "./theme/variables.scss";

import { createApp } from "vue";
import { createGtm } from '@gtm-support/vue-gtm';
import VueGtag from 'vue-gtag';
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

const pinia = createPinia();

/* Theme variables */
import { createPinia } from "pinia";

const googleGtm = createGtm({
  id: process.env.VUE_APP_GTM_ID,
  defer: false,
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
})


const app = createApp(App).use(IonicVue).use(pinia).use(router).use(googleGtm).use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_ID,
  },
});

router.isReady().then(() => {
  app.mount("#app");
});
