import {
  getEventById,
  getEventBySlug,
  getEventLanguage,
  getEventSettings,
} from "@/api/events";
import { IEventV2, IManager } from "@/models/event";
import { IExhibitor } from "@/models/exhibitor";
import { IVisitor } from "@/models/visitor";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
const customEvent = process.env.VUE_APP_CUSTOM_EVENT;

export type UserRole = "exhibitor" | "visitor" | "manager" | "admin" | "unset";

export const useContextStore = defineStore("context", () => {
  const event = ref({} as IEventV2);
  const exhibitor = ref({} as IExhibitor);
  const visitor = ref({} as IVisitor);
  const role = ref({} as UserRole);
  const manager = ref({} as IManager);
  const language = ref("pt");

  const getEventInfo = async () => {
    const settignsResponse = await getEventSettings(event.value.id);

    const settings = settignsResponse.data.results[0];

    event.value.settings = settings;

    const languagesResponse = await getEventLanguage(event.value.id);
    const languages = languagesResponse.data.results;

    event.value.languages = languages;

    // Now we have the event type and setLanguage will set the taxonomy as well
    const defaultLanguage = languages.find((language) => language.default);
    if (defaultLanguage) {
      const localStorageLang = process.env.VUE_APP_LOCAL_STORAGE_LANG_PROP;
      const lsLang = localStorage.getItem(localStorageLang);
      if (lsLang) {
        const userLanguageIsInEvent = languages.some((language) =>
          language.region_code.includes(lsLang)
        );
        if (!userLanguageIsInEvent) {
          language.value = defaultLanguage.region_code;
        } else {
          language.value = lsLang;
        }
      } else {
        language.value = defaultLanguage.region_code;
      }
    }
  };
  const getEvent = async (param: string | number) => {
    if (typeof param === "string") {
      const response = await getEventBySlug(param);
      const _event = response.data.results[0];
      if (_event) {
        event.value = _event;
        await getEventInfo();
      }
    } else {
      const response = await getEventById(param);
      const _event = response.data;
      event.value = _event;
      await getEventInfo();
    }
  };

  const setManager = (_manager: IManager) => {
    manager.value = _manager;
    role.value = "manager";
  };

  const addEventRegistration = () => {
    event.value.registered_visitors = event.value.registered_visitors + 1;
  };
  const addEventCheckin = () => {
    event.value.checked_in_visitors = event.value.checked_in_visitors + 1;
  };

  const route = computed(() => {
    if (customEvent === "") {
      return `/${event.value.slug}`;
    } else {
      return "";
    }
  });

  return {
    event,
    exhibitor,
    visitor,
    role,
    manager,
    route,
    addEventCheckin,
    addEventRegistration,
    setManager,
    getEvent,
  };
});
