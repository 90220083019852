import {
  createVisitor,
  getVisitorById,
  getVisitorTypeChoices,
  updateVisitor,
} from "@/api/visitors";
import { useToasters } from "@/composition/toasters";
import { IVisitor, IVisitorTypeChoice } from "@/models/visitor";
import { useContextStore } from "@/stores/context";
import { handleError } from "@/views";
import { computed, reactive, ref } from "vue";

export const useVisitors = () => {
  const contextStore = useContextStore();
  const toasters = useToasters();

  const state = reactive({
    loading: false,
    visitor_types: [] as IVisitorTypeChoice[],
    errors: {
      name: [],
      email: [],
      country: [],
      company: [],
      phone_number: [],
      job_title: [],
      birthdate: [],
      visitor_type: [],
    },
  });

  const form = reactive({
    name: "",
    email: "",
    country: "",
    company: "",
    phone_number: "",
    job_title: "",
    birthdate: "",
    visitor_type: undefined as undefined | number,
  });

  const data = ref({} as IVisitor);

  const loadVisitorInfo = async (visitor_id: number) => {
    state.loading = true;

    const visitor_response = await getVisitorById(visitor_id);

    data.value = visitor_response.data;

    form.name = data.value.name;
    form.email = data.value.email;
    form.country = data.value.country;
    form.birthdate = data.value.birthdate;
    form.job_title = data.value.job_title;
    form.company = data.value.company;

    loadVisitorTypes();
  };

  const loadVisitorTypes = () => {
    getVisitorTypeChoices({ event: contextStore.event.id })
      .then((response) => {
        state.visitor_types = response.data.results;
        state.loading = false;
      })
      .catch((err) => {
        handleError(err);
        state.loading = false;
      });
  };

  const create = async () => {
    state.loading = true;
    const result = await createVisitor({
      ...form,
      event: contextStore.event.id,
    }).catch((err) => {
      if (Array.isArray(err.response.data)) {
        toasters.addError(err.response.data[0]);
      } else {
        state.errors = { ...state.errors, ...err.response.data };
      }
      state.loading = false;

      throw err;
    });

    state.loading = false;

    toasters.addSuccess("Visitor created successfuly!");

    return result;
  };

  const update = async () => {
    state.loading = true;
    // await changedAnswers.value.reduce(
    //   async (previousPromise: unknown, answer) => {
    //     await previousPromise;
    //     if (answer.id) {
    //       return updateEventAttendingAnswer(answer.id, answer.answer);
    //     } else {
    //       return createAttendingField(answer);
    //     }
    //   },
    //   Promise.resolve()
    // );

    updateVisitor({ ...form, id: data.value.id })
      .then(() => {
        state.loading = false;
        toasters.addSuccess("Visitor updated successfuly!");
      })
      .catch((err) => {
        if (Array.isArray(err.response.data)) {
          toasters.addError(err.response.data[0]);
        } else {
          state.errors = { ...state.errors, ...err.response.data };
        }
        state.loading = false;
        throw err;
      });
  };

  const clear = () => {
    form.name = "";
    form.email = "";
    form.company = "";
    form.phone_number = "";
    form.job_title = "";
    form.country = "";
    form.birthdate = "";
    form.visitor_type = undefined;
  };

  const emptyFieldsPresent = computed(
    () => form.name === "" || form.email === ""
    // || form.company === "" ||
    // form.job_title === "" ||
    // form.birthdate === ""
  );

  return {
    state,
    form,
    emptyFieldsPresent,
    data,
    loadVisitorInfo,
    loadVisitorTypes,
    update,
    create,
    clear,
  };
};
