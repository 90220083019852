import axios from "axios";
import config from "./config";
import { IParams, Resolve } from ".";

const url = process.env.VUE_APP_SERVER_PATH;

export const getDefaultLabelPdf = (params: IParams) =>
  new Promise(
    (
      resolve: Resolve<{ height_mm: number; url: string; width_mm: number }>
    ) => {
      axios
        .get(
          `${url}/event-settings/${params.event}/default_label_pdf`,
          config()
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          resolve(err);
        });
    }
  );
