import { defineStore } from "pinia";
import { reactive } from "vue";
import { StatusBar, Style } from "@capacitor/status-bar";

export interface AppSettings {
  theme: "light" | "dark";
  checkin_mode: "fast" | "slow";
}

export const useAppStore = defineStore("app", () => {
  const settings = reactive({
    theme: "light",
    checkin_mode: "fast",
  });

  const toggleTheme = () => {
    settings.theme = settings.theme === "dark" ? "light" : "dark";
    document.documentElement.setAttribute("data-theme", settings.theme);
    localStorage.setItem(settings_prop, JSON.stringify(settings));
  };

  const setThene = (mode: "dark" | "light") => {
    settings.theme = mode;
    document.documentElement.setAttribute("data-theme", settings.theme);
    localStorage.setItem(settings_prop, JSON.stringify(settings));
  };

  const setCheckinMode = (checkin_mode: "slow" | "fast") => {
    settings.checkin_mode = checkin_mode;
    localStorage.setItem(settings_prop, JSON.stringify(settings));
  };

  const setSettings = (new_settings: AppSettings) => {
    settings.theme = new_settings.theme;
    settings.checkin_mode = new_settings.checkin_mode;
    document.documentElement.setAttribute("data-theme", settings.theme);
    localStorage.setItem(settings_prop, JSON.stringify(settings));
    StatusBar.setStyle({
      style: Style.Light,
    });
    StatusBar.setBackgroundColor({ color: "#7165E3" });
  };

  const settings_prop = process.env.VUE_APP_SETTINGS;
  const app_settings_string = localStorage.getItem(settings_prop);

  if (app_settings_string) {
    const new_settings = JSON.parse(app_settings_string);
    setSettings(new_settings);
  }
  return {
    settings,
    setSettings,
    setCheckinMode,
    toggleTheme,
    setThene,
  };
});
