import { getMe, getRoles, loginUser } from "@/api/auth";
import { IPermissions, IUserAuth } from "@/models/auth";
import { IEventV2, IManager } from "@/models/event";
import { IExhibitor } from "@/models/exhibitor";
import { IUser } from "@/models/user";
import { IVisitor } from "@/models/visitor";
import { defineStore } from "pinia";
import { computed, reactive, ref } from "vue";
import { useContextStore } from "./context";

export const useUserStore = defineStore("user", () => {
  const contextStore = useContextStore();

  const me = reactive({} as IUser);

  const links = reactive({
    manages: {} as Partial<IPermissions>,
    exhibits: {} as Partial<IPermissions>,
    admins: {} as Partial<IPermissions>,
    visits: {} as Partial<IPermissions>,
    owns: {} as Partial<IPermissions>,
  });

  const related = reactive({
    events: [] as IEventV2[],
    exhibitors: [] as IExhibitor[],
    visitors: [] as IVisitor[],
    event_managers: [] as IManager[],
    clients: {} as { client_name: string; id: number }[],
    allocated_capacity: 0 as number,
  });

  const token = ref("");

  const userIsLoggedIn = ref(false);

  const userRolesSet = ref(false);

  let userIsAdmin = computed(
    () =>
      links.owns &&
      links.owns.ids &&
      links.owns.ids.some((event) => event === contextStore.event.id)
  );

  const userIsExhibitor = computed(() =>
    related.exhibitors.some(
      (exhibitor) => exhibitor.event === contextStore.event.id
    )
  );

  const userIsVisitor = computed(() =>
    related.visitors.some((visitor) => visitor.event === contextStore.event.id)
  );
  const userManager = computed(() =>
    related.event_managers.find(
      (manager) => manager.event === contextStore.event.id
    )
  );
  const userExhibitor = computed(() =>
    related.exhibitors.find(
      (exhibitor) => exhibitor.event === contextStore.event.id
    )
  );
  const userVisitor = computed(() =>
    related.visitors.find((visitor) => visitor.event === contextStore.event.id)
  );
  let userIsManager = computed(
    () =>
      userIsAdmin.value ||
      (links.manages.ids &&
        links.manages.ids.some((event) => event === contextStore.event.id))
  );

  const getUserPermissions = () => {
    // Update user permissions - usefull to call when in a event context
    userIsAdmin = computed(
      () =>
        links.owns &&
        links.owns.ids &&
        links.owns.ids.some((event) => event === contextStore.event.id)
    );

    userIsManager = computed(
      () =>
        userIsAdmin.value ||
        (links.manages.ids &&
          links.manages.ids.some((event) => event === contextStore.event.id))
    );
  };

  const login = async (params: IUserAuth) => {
    const data = {
      ...params,
      strategy: "ca",
    };
    const response = await loginUser(data).catch((err) => {
      throw err;
    });

    setUserAccessToken(response.data.token);

    const localStorageAuthProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;
    localStorage.setItem(localStorageAuthProp, token.value);
    return response.data;
  };

  const localLogin = () => {
    const localStorageAuthProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;
    const token = localStorage.getItem(localStorageAuthProp);
    if (token) {
      setUserAccessToken(token);
    }
    return token;
  };

  const fetchMe = async () => {
    const response = await getMe();

    me.id = response.data.id;
    me.email = response.data.email;
    me.name = response.data.name;
    me.language = response.data.language;
    me.phone_number = response.data.phone_number;
    me.profile_picture = response.data.profile_picture;
    me.profile_picture = response.data.profile_picture;
  };

  const fetchRoles = async () => {
    const response = await getRoles();

    const v2 = response.data;

    userRolesSet.value = true;

    if (v2.links) {
      if (v2.links.admins) {
        links.admins = v2.links.admins;
      } else {
        links.admins = {
          href: "",
          ids: [],
        };
      }
      if (v2.links.manages) {
        links.manages = v2.links.manages;
      } else {
        links.manages = {
          href: "",
          ids: [],
        };
      }
      if (v2.links.exhibits) {
        links.exhibits = v2.links.exhibits;
      } else {
        links.exhibits = {
          href: "",
          ids: [],
        };
      }
      if (v2.links.visits) {
        links.visits = v2.links.visits;
      } else {
        links.visits = {
          href: "",
          ids: [],
        };
      }
      if (v2.links.owns) {
        links.owns = v2.links.owns;
      } else {
        links.owns = {
          href: "",
          ids: [],
        };
      }
    }

    related.events = [];
    related.exhibitors = [];
    related.visitors = [];
    related.event_managers = [];
    related.allocated_capacity = 0;

    if (v2.related) {
      related.events = v2.related.events.filter(
        (event) =>
          (links.manages.ids && links.manages.ids.includes(event.id)) ||
          (links.owns.ids && links.owns.ids.includes(event.id))
      );
      related.exhibitors = v2.related.exhibitors;
      related.visitors = v2.related.visitors;
      related.event_managers = v2.related.event_managers;
      related.clients = v2.related.clients;
    }
  };

  const setUserProfilePic = (param: string) => {
    me.profile_picture = param;
  };

  const setUserAccessToken = (param: string) => {
    token.value = param;
    userIsLoggedIn.value = true;
  };

  const setUserLogout = () => {
    links.manages = {};
    links.exhibits = {};
    links.admins = {};
    links.visits = {};

    related.events = [];
    related.exhibitors = [];
    related.visitors = [];
    related.event_managers = [];

    token.value = "";
    userIsLoggedIn.value = false;
    userRolesSet.value = false;

    const localStorageAuthProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;
    localStorage.removeItem(localStorageAuthProp);
  };

  return {
    login,
    fetchMe,
    fetchRoles,
    setUserProfilePic,
    setUserAccessToken,
    setUserLogout,
    localLogin,
    getUserPermissions,
    related,
    token,
    userRolesSet,
    userIsLoggedIn,
    userIsAdmin,
    userIsExhibitor,
    userIsVisitor,
    userIsManager,
    userVisitor,
    userExhibitor,
    userManager,
    me,
    links,
  };
});
