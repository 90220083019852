import { IClient } from "@/models/client";
import axios from "axios";
import { Resolve } from ".";
import config from "./config";

const url = process.env.VUE_APP_SERVER_PATH;

export const getClientAccount = (clientId: number) =>
  new Promise((resolve: Resolve<IClient>, reject) => {
    const requestUrl = `${url}/v2/client-accounts/${clientId}`;

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateClientAccount = (clientId: number, params: IClient) =>
  new Promise((resolve: Resolve<IClient>, reject) => {
    const requestUrl = `${url}/v2/client-accounts/${clientId}`;

    axios
      .patch(requestUrl, params, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
