import { getVisitors } from "@/api/visitors";
import { IVisitor, IVisitorsParams } from "@/models/visitor";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useVisitorsStore = defineStore("visitors", () => {
  const results = ref([] as IVisitor[]);
  const current = ref(1);
  const page_count = ref(0);
  const item_count = ref(0);
  const loading = ref(false);

  const fetch = async (params: IVisitorsParams) => {
    loading.value = true;
    if (params.reset) {
      results.value = [];
    }
    const response = await getVisitors(params);
    loading.value = false;
    results.value = params.reset
      ? response.data.results
      : [...results.value, ...response.data.results];
    current.value = response.data.current;
    page_count.value = response.data.page_count;
    item_count.value = response.data.item_count;

    return {
      results: results.value,
      current: current.value,
      page_count: page_count.value,
      item_count: item_count.value,
      next: current.value + 1,
      previous: current.value - 1,
    };
  };
  return {
    fetch,
    results,
    current,
    page_count,
    item_count,
    loading,
  };
});
