import { toastController, ToastOptions, ToastButton } from "@ionic/vue";
import { bug, checkmarkCircle, warning } from "ionicons/icons";
export const useToasters = () => {
  const add = async (options: ToastOptions) => {
    const toast = await toastController.create(options);
    toast.present();
    return toast;
  };
  const addError = (message: string) => {
    add({
      message,
      duration: 5000,
      color: "danger",
      icon: bug,
      position: "top",
    });
  };
  const addSuccess = (message: string) => {
    add({
      message,
      duration: 5000,
      color: "success",
      icon: checkmarkCircle,
      position: "top",
    });
  };
  const addWarning = (message: string, buttons?: ToastButton[]) => {
    add({
      message,
      duration: 5000,
      color: "warning",
      icon: warning,
      position: "top",
      buttons: buttons,
    });
  };
  return {
    add,
    addError,
    addSuccess,
    addWarning,
  };
};
