import { getPlans, getPlan } from "@/api/plans";
import { IPlan, IPlanParams } from "@/models/plan";
import { defineStore } from "pinia";
import { ref } from "vue";

export const usePlansStore = defineStore("plans", () => {
  const results = ref([] as IPlan[]);
  const current = ref(1);
  const currentPlan = ref({} as IPlan);
  const page_count = ref(0);
  const item_count = ref(0);
  const loading = ref(false);

  const fetch = async (params: IPlanParams) => {
    loading.value = true;
    const response = await getPlans(params);
    loading.value = false;
    results.value = params.reset
      ? response.data.results
      : [...results.value, ...response.data.results];
    current.value = response.data.current;
    page_count.value = response.data.page_count;
    item_count.value = response.data.item_count;

    return {
      results: results.value,
      current: current.value,
      page_count: page_count.value,
      item_count: item_count.value,
      next: current.value + 1,
      previous: current.value - 1,
    };
  };

  const fetchPlan = async (planId: number) => {
    loading.value = true;
    const response = await getPlan(planId);
    loading.value = false;
    currentPlan.value = response.data;

    return {
      result: currentPlan.value,
    };
  };

  return {
    fetch,
    fetchPlan,
    loading,
    results,
    currentPlan,
    current,
    page_count,
    item_count,
  };
});
