import {
  IChargePayload,
  IClientPaymentPlanParams,
  IPlan,
  IPlanParams,
} from "@/models/plan";
import axios from "axios";
import { Resolve, IData } from ".";
import config from "./config";

const url = process.env.VUE_APP_SERVER_PATH;

export const getPlans = (params: IPlanParams) =>
  new Promise((resolve: Resolve<IData<IPlan>>, reject) => {
    if (!params.page_size) {
      params.page_size = 100;
    }

    if (!params.page) {
      params.page = 1;
    }

    // We'll fetch checkin app plans only
    const requestUrl = `${url}/v2/payment-plans?page_size=${params.page_size}&page=${params.page}&ordering=id&check_in_app_service=true`;

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getPlan = (planId: number) =>
  new Promise((resolve: Resolve<IPlan>, reject) => {
    const requestUrl = `${url}/v2/payment-plans/${planId}`;

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getSubscribedPlans = () =>
  new Promise((resolve: Resolve<IData<IClientPaymentPlanParams>>, reject) => {
    const requestUrl = `${url}/v2/client-payment-plans`;

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const createClientPlan = (params: IClientPaymentPlanParams) =>
  new Promise((resolve: Resolve<IClientPaymentPlanParams>, reject) => {
    const requestUrl = `${url}/v2/client-payment-plans`;

    axios
      .post(requestUrl, params, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const stripeCharge = (
  clientPaymentPlan: number,
  payload: IChargePayload
) =>
  new Promise((resolve, reject) => {
    const requestUrl = `${url}/v2/client-payment-plans/${clientPaymentPlan}/stripe_charge`;

    axios
      .post(requestUrl, payload, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
