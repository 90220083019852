import axios from "axios";
import {
  ICalendarParams,
  ISession,
  ISessionChat,
  ISessionRegistration,
  ISessionParams,
  ISessionLogsParams,
  ISessionLog,
  ISessionCapacity,
} from "@/models/session";
import { Resolve, IData } from ".";
import config from "./config";

const url = process.env.VUE_APP_SERVER_PATH;

export const getSessions = (params: ISessionParams) =>
  new Promise((resolve: Resolve<IData<ISession>>, reject) => {
    // todo Not sure why page_size is automatically 100, but I'm keeping it, to not break anything else
    if (!params.page_size) {
      params.page_size = 100;
    }
    if (!params.page) {
      params.page = 1;
    }

    let requestUrl = `${url}/v2/sessions?page=${params.page}&page_size=${params.page_size}&ordering=_start_date`;
    if (params.timezone_id) {
      requestUrl += `&timezone_id=${params.timezone_id}`;
    }
    if (params.event) {
      requestUrl += `&event=${params.event}`;
    }
    if (params.stage) {
      requestUrl += `&stage=${params.stage}`;
    }
    if (params.pending !== undefined) {
      requestUrl += `&pending=${params.pending}`;
    }
    if (params.visitor) {
      requestUrl += `&attending=${params.visitor}`;
    }

    if (params.hide_on_calendar !== undefined) {
      requestUrl += `&hide_on_calendar=${params.hide_on_calendar}`;
    }
    if (params.exhibitor) {
      requestUrl += `&exhibitor=${params.exhibitor}`;
    }
    if (params.translations) {
      requestUrl += "&_translations=true";
    }
    if (params.status) {
      requestUrl += `&status=${params.status}`;
    }
    if (params.is_published) {
      requestUrl += `&is_published=${params.is_published}`;
    }
    if (params.registered) {
      requestUrl += `&registered=${params.registered}`;
    }
    if (params.private) {
      requestUrl += `&private=${params.private}`;
    }
    if (params.start_date) {
      requestUrl += `&start_date=${encodeURIComponent(params.start_date)}`;
    }
    if (params.end_date) {
      requestUrl += `&end_date=${params.end_date}`;
    }
    if (params.start_date_gte) {
      requestUrl += `&start_date_gte=${params.start_date_gte}`;
    }
    if (params.end_date_gte) {
      requestUrl += `&end_date_gte=${params.end_date_gte}`;
    }
    if (params.start_date_lte) {
      requestUrl += `&start_date_lte=${params.start_date_lte}`;
    }
    if (params.end_date_lte) {
      requestUrl += `&end_date_lte=${params.end_date_lte}`;
    }
    if (params.sponsor_level) {
      requestUrl += `&sponsor_level=${params.sponsor_level}`;
    }
    if (params.search) {
      requestUrl += `&search=${params.search}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getEventSessions = (params: ISessionParams) =>
  new Promise((resolve: Resolve<IData<ISession>>, reject) => {
    if (!params.page_size) {
      params.page_size = 100;
    }
    if (!params.page) {
      params.page = 1;
    }

    let requestUrl = `${url}/v2/event-sessions?page=${params.page}&page_size=${params.page_size}&ordering=_start_date`;
    if (params.timezone_id) {
      requestUrl += `&timezone_id=${params.timezone_id}`;
    }
    if (params.event) {
      requestUrl += `&event=${params.event}`;
    }
    if (params.hide_on_calendar !== undefined) {
      requestUrl += `&hide_on_calendar=${params.hide_on_calendar}`;
    }
    if (params.stage) {
      requestUrl += `&stage=${params.stage}`;
    }

    if (params.exhibitor) {
      requestUrl += `&exhibitor=${params.exhibitor}`;
    }
    if (params.status) {
      requestUrl += `&status=${params.status}`;
    }
    if (params.is_published !== undefined) {
      requestUrl += `&is_published=${params.is_published}`;
    }
    if (params.visitor) {
      requestUrl += `&attending=${params.visitor}`;
    }
    if (params.translations) {
      requestUrl += "&_translations=true";
    }
    if (params.registered !== undefined) {
      requestUrl += `&registered=${params.registered}`;
    }
    if (params.private !== undefined) {
      requestUrl += `&private=${params.private}`;
    }
    if (params.start_date) {
      requestUrl += `&start_date=${encodeURIComponent(params.start_date)}`;
    }
    if (params.end_date) {
      requestUrl += `&end_date=${params.end_date}`;
    }
    if (params.start_date_gte) {
      requestUrl += `&start_date_gte=${params.start_date_gte}`;
    }
    if (params.end_date_gte) {
      requestUrl += `&end_date_gte=${params.end_date_gte}`;
    }
    if (params.start_date_lte) {
      requestUrl += `&start_date_lte=${params.start_date_lte}`;
    }
    if (params.end_date_lte) {
      requestUrl += `&end_date_lte=${params.end_date_lte}`;
    }

    if (params.search) {
      requestUrl += `&search=${params.search}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getSessionChat = (session: number) =>
  new Promise((resolve: Resolve<IData<ISessionChat>>, reject) => {
    axios
      .get(`${url}/v2/session-chat-rooms?session=${session}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const createSessionChat = (session: number) =>
  new Promise((resolve: Resolve<ISessionChat>, reject) => {
    axios
      .post(`${url}/v2/session-chat-rooms`, { session }, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const deleteSessionChat = (sessionChatId: number) =>
  new Promise((resolve: Resolve<any>, reject) => {
    axios
      .delete(`${url}/v2/session-chat-rooms/${sessionChatId}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getSessionById = (session: number) =>
  new Promise((resolve: Resolve<ISession>, reject) => {
    axios
      .get(`${url}/v2/sessions/${session}?_translations=true`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getEventSessionById = (session: number) =>
  new Promise((resolve: Resolve<ISession>, reject) => {
    axios
      .get(`${url}/v2/event-sessions/${session}?_translations=true`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getExhibitorSessionById = (session: number) =>
  new Promise((resolve: Resolve<ISession>, reject) => {
    axios
      .get(
        `${url}/v2/exhibitor-sessions/${session}?_translations=true`,
        config()
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const enterSession = (params: ISessionParams) =>
  new Promise(
    (resolve: Resolve<{ attending: number; session: number }>, reject) => {
      axios
        .post(
          `${url}/v2/session-entrances`,
          {
            event: params.event,
            session: params.session,
            attending: params.visitor,
          },
          config()
        )
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    }
  );

export const getExhibitorSessions = (params: ISessionParams) =>
  new Promise((resolve: Resolve<IData<ISession>>, reject) => {
    if (!params.page_size) {
      params.page_size = 100;
    }
    if (!params.page) {
      params.page = 1;
    }

    let requestUrl = `${url}/v2/exhibitor-sessions?page=${params.page}&page_size=${params.page_size}&ordering=_start_date`;
    if (params.timezone_id) {
      requestUrl += `&timezone_id=${params.timezone_id}`;
    }
    if (params.non_visitor) {
      requestUrl += `&non_visitor=${params.non_visitor}`;
    }
    if (params.event) {
      requestUrl += `&event=${params.event}`;
    }
    if (params.stage) {
      requestUrl += `&stage=${params.stage}`;
    }
    if (params.pending !== undefined) {
      requestUrl += `&pending=${params.pending}`;
    }
    if (params.hide_on_calendar !== undefined) {
      requestUrl += `&hide_on_calendar=${params.hide_on_calendar}`;
    }
    if (params.visitor) {
      requestUrl += `&attending=${params.visitor}`;
    }
    if (params.exhibitor) {
      requestUrl += `&exhibitor=${params.exhibitor}`;
    }
    if (params.status) {
      requestUrl += `&status=${params.status}`;
    }
    if (params.is_published !== undefined) {
      requestUrl += `&is_published=${params.is_published}`;
    }
    if (params.translations) {
      requestUrl += "&_translations=true";
    }
    if (params.registered !== undefined) {
      requestUrl += `&registered=${params.registered}`;
    }
    if (params.private !== undefined) {
      requestUrl += `&private=${params.private}`;
    }
    if (params.start_date) {
      requestUrl += `&start_date=${encodeURIComponent(params.start_date)}`;
    }
    if (params.end_date) {
      requestUrl += `&end_date=${params.end_date}`;
    }
    if (params.start_date_gte) {
      requestUrl += `&start_date_gte=${params.start_date_gte}`;
    }
    if (params.end_date_gte) {
      requestUrl += `&end_date_gte=${params.end_date_gte}`;
    }
    if (params.start_date_lte) {
      requestUrl += `&start_date_lte=${params.start_date_lte}`;
    }
    if (params.end_date_lte) {
      requestUrl += `&end_date_lte=${params.end_date_lte}`;
    }

    if (params.search) {
      requestUrl += `&search=${params.search}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const addSession = (session: Partial<ISession>) =>
  new Promise((resolve: Resolve<ISession>, reject) => {
    axios
      .post(`${url}/v2/sessions`, session, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateSession = (session: Partial<ISession>) =>
  new Promise((resolve: Resolve<ISession>, reject) => {
    axios
      .patch(`${url}/v2/sessions/${session.id}`, session, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const deleteSession = (session: Partial<ISession>) =>
  new Promise((resolve: Resolve<ISession>, reject) => {
    axios
      .delete(`${url}/v2/sessions/${session.id}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const addSessionCapacity = (
  sessionCapacity: Partial<ISessionCapacity>
) =>
  new Promise((resolve: Resolve<ISessionCapacity>, reject) => {
    axios
      .post(`${url}/v2/session-capacities`, sessionCapacity, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const deleteSessionRegistration = (id: number) =>
  new Promise((resolve: Resolve<ISessionRegistration>, reject) => {
    axios
      .delete(`${url}/v2/session-registrations/${id}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const sessionRegistration = (session: ISessionRegistration) =>
  new Promise((resolve: Resolve<ISessionRegistration>, reject) => {
    axios
      .post(`${url}/v2/session-registrations`, session, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateSessionRegistration = (session: ISessionRegistration) =>
  new Promise((resolve: Resolve<ISessionRegistration>, reject) => {
    axios
      .patch(`${url}/v2/session-registrations/${session.id}`, session, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getSessionRegistrations = (params: ISessionParams) =>
  new Promise((resolve: Resolve<IData<ISessionRegistration>>, reject) => {
    let requestUrl = `${url}/v2/session-registrations?page_size=${
      params.page_size ? params.page_size : 1000
    }`;

    if (params.event) {
      requestUrl += `&event=${params.event}`;
    }

    if (params.session) {
      requestUrl += `&session=${params.session}`;
    }

    if (params.visitor) {
      requestUrl += `&attending=${params.visitor}`;
    }

    if (params.exhibitor) {
      requestUrl += `&exhibitor=${params.exhibitor}`;
    }

    if (params.status) {
      requestUrl += `&status=${params.status}`;
    }

    if (params.statuses) {
      requestUrl += `&statuses=${params.statuses}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const getCalendarBlocks = (params: ICalendarParams) =>
  new Promise((resolve: Resolve<string[]>, reject) => {
    let requestUrl = `${url}/v2/session-calendar-blocks?page_size=${
      params.page_size ? params.page_size : 1000
    }`;

    if (params.event) {
      requestUrl += `&event=${params.event}`;
    }
    if (params.hide_on_calendar !== undefined) {
      requestUrl += `&hide_on_calendar=${params.hide_on_calendar}`;
    }
    if (params.session_type && params.session_type !== "all") {
      requestUrl += `&session_type=${params.session_type}`;
    }

    if (params.visitor) {
      requestUrl += `&attending=${params.visitor}`;
    }
    if (params.exhibitor_sponsor_level) {
      requestUrl += `&exhibitor_sponsor_level=${params.exhibitor_sponsor_level}`;
    }
    if (params.stage) {
      requestUrl += `&stage=${params.stage}`;
    }
    if (params.timezone_id) {
      requestUrl += `&timezone_id=${params.timezone_id}`;
    }
    if (params.calendar_blocks_timezone_id) {
      requestUrl += `&calendar_blocks_timezone_id=${params.calendar_blocks_timezone_id}`;
    }

    if (params.exhibitor) {
      requestUrl += `&exhibitor=${params.exhibitor}`;
    }

    if (params.search) {
      requestUrl += `&search=${params.search}`;
    }

    if (params.registered !== undefined) {
      requestUrl += `&registered=${params.registered}`;
    }

    if (params.is_published !== undefined) {
      requestUrl += `&is_published=${params.is_published}`;
    }

    if (params.private !== undefined) {
      requestUrl += `&private=${params.private}`;
    }

    if (params.sponsor_level !== undefined) {
      requestUrl += `&sponsor_level=${params.sponsor_level}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const getSessionRegistration = (id: number) =>
  new Promise((resolve: Resolve<ISessionRegistration>, reject) => {
    axios
      .get(`${url}/v2/session-registrations/${id}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const sessionRegistrationUpdate = (
  sessionRegistration: Partial<ISessionRegistration>
) =>
  new Promise((resolve: Resolve<ISessionRegistration>, reject) => {
    axios
      .patch(
        `${url}/v2/session-registrations/${sessionRegistration.id}`,
        sessionRegistration,
        config()
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const sessionRegistrationCreate = (
  sessionRegistration: ISessionRegistration
) =>
  new Promise((resolve: Resolve<ISessionRegistration>, reject) => {
    axios
      .post(`${url}/v2/session-registrations`, sessionRegistration, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const sessionEntranceCreate = (
  sessionRegistration: ISessionRegistration
) =>
  new Promise((resolve: Resolve<ISessionRegistration>, reject) => {
    axios
      .post(`${url}/v2/session-entrances`, sessionRegistration, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getOnlineSessionRegistrations = (params: ISessionParams) =>
  new Promise((resolve: Resolve<IData<ISessionRegistration>>, reject) => {
    let requestUrl = `${url}/v2/online-session-registrations?page_size=${
      params.page_size ? params.page_size : 1000
    }`;

    if (params.event) {
      requestUrl += `&event=${params.event}`;
    }

    if (params.session) {
      requestUrl += `&session=${params.session}`;
    }

    if (params.visitor) {
      requestUrl += `&attending=${params.visitor}`;
    }

    if (params.exhibitor) {
      requestUrl += `&exhibitor=${params.exhibitor}`;
    }

    if (params.status) {
      requestUrl += `&status=${params.status}`;
    }

    if (params.statuses) {
      requestUrl += `&statuses=${params.statuses}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const getOnlineSessionRegistration = (id: number) =>
  new Promise((resolve: Resolve<ISessionRegistration>, reject) => {
    axios
      .get(`${url}/v2/online-session-registrations/${id}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const onlineSessionRegistrationUpdate = (
  sessionRegistration: Partial<ISessionRegistration>
) =>
  new Promise((resolve: Resolve<ISessionRegistration>, reject) => {
    axios
      .patch(
        `${url}/v2/online-session-registrations/${sessionRegistration.id}`,
        sessionRegistration,
        config()
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const onlineSessionRegistrationCreate = (
  sessionRegistration: ISessionRegistration
) =>
  new Promise((resolve: Resolve<ISessionRegistration>, reject) => {
    axios
      .post(
        `${url}/v2/online-session-registrations`,
        sessionRegistration,
        config()
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const onlineSessionEntranceCreate = (
  sessionRegistration: ISessionRegistration
) =>
  new Promise((resolve: Resolve<ISessionRegistration>, reject) => {
    axios
      .post(`${url}/v2/online-session-entrances`, sessionRegistration, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getStages = (eventId: number) =>
  axios.get(`${url}/v2/stages?event=${eventId}`, config());

export const getSessionLogs = (params: ISessionLogsParams) =>
  new Promise((resolve: Resolve<IData<ISessionLog>>, reject) => {
    if (!params.page_size) {
      params.page_size = 100;
    }
    if (!params.page) {
      params.page = 1;
    }
    let requestUrl = `${url}/organizers/events/${params.event}/session_logs/search?page_size=${params.page_size}&page=${params.page}&ordering=-id&keyword`;

    if (params.session_id) {
      requestUrl += `&session_id=${params.session_id}`;
    }
    if (params.attending_id) {
      requestUrl += `&attending_id=${params.attending_id}`;
    }
    if (params.visitor_exists) {
      requestUrl += `&visitor_exists=${params.visitor_exists}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
