import { useToasters } from "@/composition/toasters";
import { IEventV2, IVisitorFormSettings } from "@/models/event";
import { reactive } from "vue";

const event: Partial<IEventV2> = reactive({
  name: "",
  start_date: "",
  end_date: "",
  // slug: "",
  location: "",
  description: "",
  is_published: false,
  timezone_id: Intl.DateTimeFormat().resolvedOptions().timeZone,
  capacity: 100,
  language: "",
  client_account: undefined,
  type: "ci",
});

const user = reactive({
  name: "",
  email: "",
  password: "",
  confirm_password: "",
  emails_opt_in: false,
});

// const settings = reactive({
//   visitor_form: true,
//   static_fields: [
//     {
//       active: false,
//       title: "" as string | undefined,
//       id: 0,
//     },
//   ],
// });

const form_settings: Partial<IVisitorFormSettings> = reactive({
  phone_number: "v",
  birthdate: "v",
  job_title: "v",
  company: "v",
  country: "v",
});

export const handleError = (err: any) => {
  const toasters = useToasters();
  // Use this to handle all generic errors
  if (err.response.data.detail) {
    toasters.addError(err.response.data.detail);
  } else if (typeof err.response.data === "object") {
    Object.keys(err.response.data).forEach((key) => {
      const text = key.toString() + " " + err.response.data[key][0];
      toasters.addError(text);
    });
  } else {
    toasters.addError("An error has occured. Please try again later.");
  }
};

export const useForm = () => ({ user, event, form_settings });
