import { defineStore } from "pinia";
import { ref } from "vue";
import _ from "lodash";

import { useContextStore } from "./context";
import { AvailableLanguages } from "@/models/event";

const defaultTranslations = {
  pt: {},
  en: {},
  es: {},
  fr: {},
  de: {},
};

const jobfairTranslations = {
  pt: {},
  en: {},
  es: {},
  fr: {},
  de: {},
};

const trTranslations = {
  pt: {},
  en: {},
  es: {},
  fr: {},
  de: {},
};

const tr2Translations = {
  pt: {},
  en: {},
  es: {},
  fr: {},
  de: {},
};

const votingTranslations = {
  pt: {},
  en: {},
  es: {},
  fr: {},
  de: {},
};

export const useLanguageStore = defineStore("language", () => {
  const language = ref("en");
  const translations = ref(defaultTranslations.pt as any);
  const lang = ref(defaultTranslations.pt as Record<symbol, unknown>);
  const contextStore = useContextStore();
  const setLanguage = (_language: AvailableLanguages) => {
    language.value = _language;
    lang.value = defaultTranslations[_language];
    const contextEvent = contextStore.event;
    if (contextEvent.settings) {
      if ([470, 559, 488, 575, 487, 585, 583].includes(contextEvent.id)) {
        // Exponor custom taxonomy
        lang.value = _.merge(lang.value, tr2Translations[_language]);
      } else if (contextEvent.settings.event_type === "jf") {
        lang.value = _.merge(lang.value, jobfairTranslations[_language]);
      } else if (contextEvent.settings.event_type === "tr") {
        lang.value = _.merge(lang.value, trTranslations[_language]);
      } else if (contextEvent.settings.event_type === "vt") {
        lang.value = _.merge(lang.value, votingTranslations[_language as "pt"]);
      }
    }
  };

  return {
    setLanguage,
    translations,
    language,
    lang,
  };
});
