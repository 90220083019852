import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import Onboarding from "@/views/Onboarding/Onboarding.vue";
import Home from "@/views/Home.vue";
import Login from "@/views/User/pages/Login.vue";
import MyAccount from "@/views/User/pages/MyAccount.vue";
import ResetPassword from "@/views/User/pages/ResetPassword.vue";
import Introduction from "@/views/Onboarding/pages/Introduction.vue";
import NewAccount from "@/views/Onboarding/pages/NewAccount.vue";
import VerifyEmail from "@/views/Onboarding/pages/VerifyEmail.vue";
import Welcome from "@/views/Onboarding/pages/Welcome.vue";
import NewEvent from "@/views/Onboarding/pages/NewEvent.vue";
import ManageEvents from "@/views/Onboarding/pages/ManageEvents.vue";
import Success from "@/views/Onboarding/pages/Success.vue";
import Event from "@/views/Event/Event.vue";
import EventHome from "@/views/Event/pages/Home.vue";
import Visitors from "@/views/Event/pages/Visitors/Visitors.vue";
import Scan from "@/views/Event/pages/Scan.vue";
import Settings from "@/views/Event/pages/Settings/Settings.vue";
import SettingsForm from "@/views/Event/pages/Settings/Form.vue";
import SettingsImages from "@/views/Event/pages/Settings/Images.vue";
import Visitor from "@/views/Event/pages/Visitors/Visitor.vue";
import EditVisitor from "@/views/Event/pages/Visitors/EditVisitor.vue";
import AddVisitors from "@/views/Event/pages/Visitors/AddVisitors.vue";
import NewVisitor from "@/views/Event/pages/Visitors/NewVisitor.vue";
import ImportVisitors from "@/views/Event/pages/Visitors/ImportVisitors.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/onboarding/introduction",
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  // fixme this page allows seeing all events, needs fixing first
  // {
  //   path: "/events",
  //   component: Events,
  // },
  {
    path: "/my-account",
    component: MyAccount,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
  },
  {
    path: "/manage-events",
    component: ManageEvents,
  },
  {
    path: "/onboarding",
    component: Onboarding,
    children: [
      {
        path: "introduction",
        component: Introduction,
      },
      {
        path: "new-account",
        component: NewAccount,
      },
      {
        path: "verify-email",
        component: VerifyEmail,
      },
      {
        path: "welcome",
        component: Welcome,
      },
      {
        path: "new-event",
        component: NewEvent,
      },
      {
        path: "success",
        component: Success,
      },
    ],
  },

  {
    path: "/event/:event_id",
    component: Event,
    children: [
      {
        path: "home",
        component: EventHome,
      },
      {
        path: "visitors",
        component: Visitors,
      },
      {
        path: "visitors/:checkin_status",
        component: Visitors,
      },
      {
        path: "scan",
        component: Scan,
      },

      {
        path: "settings",
        component: Settings,
      },

      {
        path: "add-visitors",
        component: AddVisitors,
      },
      {
        path: "new-visitor",
        component: NewVisitor,
      },
      {
        path: "import-visitors",
        component: ImportVisitors,
      },
      {
        path: "visitor/:visitor_id",
        component: Visitor,
      },
      {
        path: "settings-form",
        component: SettingsForm,
      },
      {
        path: "settings-images",
        component: SettingsImages,
      },
      {
        path: "visitor/:visitor_id",
        component: Visitor,
      },
      {
        path: "edit-visitor/:visitor_id",
        component: EditVisitor,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
